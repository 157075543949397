import { IApiAddress } from "./addresses";

export interface IApiTag {
  id: number;
  title: string;
  icon: string;
  is_active: boolean;
}

export interface IApiFile {
  id?: number; // not defined for default picture
  name: string;
  file: string;
  thumbnail: string;
}

export interface IApiBrand {
  id: number;
  name: string;
}

export type IApiBrandsList = IApiBrand[];

export interface IApiSellerBrand {
  id: number;
  name: string;
  is_active: boolean;
  is_custom: boolean;
  store: number | null;
}

export type IApiSellerBrandsList = IApiSellerBrand[];

export interface IApiPhone {
  id: number;
  phone_number: string;
  is_active: boolean;
}

export enum ProductTradeCode {
  GTIN = "gtin",
  EAN = "ean",
  GCID = "gcid",
  ISBN = "isbn",
  UPC = "upc",
  ASIN = "asin",
}

export interface IApiProductTradeCode {
  code_id: ProductTradeCode;
  value: string;
}

export type IApiProductTradeCodesList = IApiProductTradeCode[];

export type IApiTypeSocial = number;

export interface IApiSocial {
  id: number;
  social_type_id: IApiTypeSocial;
  link_text: string;
  is_active: boolean;
  social_title: string;
  social_icon: string;
}

export interface IApiSocialNetwork {
  id: number;
  title: string;
  icon: string;
  is_active: boolean;
}

export type IApiSocialNetworksList = IApiSocialNetwork[];

export interface IApiSellerStoresDelivery {
  id: number;
  delivery_options: IApiDeliveryOption;
  attachment: IApiAttachment | null;
  region: string;
  rate: string;
  weight: string;
  comment: string;
  is_active: boolean;
  time: number;
}

export type IApiAddressesList = IApiAddress[];

export interface IApiSellerStoresAddress {
  id: number;
  city?: string;
  zip_code?: string;
  address_line1?: string;
  address_line2?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  is_active: boolean;
  country?: string | null;
  province?: number | null;
}

export type IApiSellerStoresAddressesList = IApiSellerStoresAddress[];

export interface IApiSellerStoresDelivery {
  id: number;
  delivery_options: IApiDeliveryOption;
  attachment: IApiAttachment | null;
  region: string;
  rate: string;
  weight: string;
  comment: string;
  is_active: boolean;
  time: number;
}

export type IApiSellerStoresDeliveriesList = IApiSellerStoresDelivery[];

export type IApiTypeTime = number;

export interface IApiAttachment {
  id: number;
  name: string;
  extension: string;
  size: number;
  created: string;
  modified: string;
  file: string;
  thumbnail: string;
  // TODO need to remove owner id for public
  owner: number;
  // TODO need to remove owner id for public
  catalog: number;
}

export interface IApiStoreDeliveryInfo {
  id: number;
  delivery_options: IApiDeliveryOption;
  attachment?: IApiAttachment | null;
  region: string;
  rate: string;
  weight: string;
  comment?: string;
  is_active: boolean;
  time: IApiTypeTime;
}

export enum IApiDeliveryProviderName {
  NOVA_POSHTA = "nova_poshta",
  UKR_POSHTA = "ukr_poshta",
}

export interface IApiDeliveryProvider {
  id: number;
  name: IApiDeliveryProviderName;
  description: string;
}

export type IApiDeliveryProviders = IApiDeliveryProvider[];

export interface IApiDeliveryCityBranch {
  branch_id: string;
  city_id: string;
  present: string;
}

export interface IApiDeliveryOption {
  id: number;
  name: string;
}

export type IApiDeliveryOptionsList = IApiDeliveryOption[];

export interface IApiLanguage {
  code: string;
  name: string;
  is_active: boolean;
  is_main: boolean;
  ordering: number;
}

export type IApiLanguagesList = IApiLanguage[];

export interface IApiCountry {
  code: string;
  name: string;
  has_provinces: boolean;
  is_active: boolean;
}

export type IApiCountriesList = IApiCountry[];

export interface IApiProvince {
  id: number;
  name: string;
  is_active: boolean;
  country: string;
}

export type IApiProvincesList = IApiProvince[];

export interface IApiCurrency {
  code: string;
  name: string;
  rate: string;
  symbol: string;
  is_active: boolean;
}

export type IApiCurrenciesList = IApiCurrency[];

export interface IApiDependency {
  country: string;
  currency: string;
  language: string;
  currencyName: string;
  currencySymbol: string;
}

export interface IApiMessenger {
  code: string;
  name: string;
}

export enum DEVICE_SIZE {
  SMALL = "small", // size < 375
  MOBILE = "mobile", // size >= 375 && size < 768
  TABLET = "tablet", // size >= 768 && size < 1024
  MEDIUM = "medium", // size >= 1024 && size < 1440
  BIG = "big", // size >= 1440
}

export interface IApiPhoneNumber {
  operator: string;
  phone_number: string;
}

export interface IApiWorkTime {
  day: string;
  time: string;
}

export interface IApiOffice {
  city?: string;
  address?: string;
  map_image_url?: string;
  work_time: IApiWorkTime[];
}

export interface IApiContactsInfo {
  phone_numbers: IApiPhoneNumber[];
  work_time: IApiWorkTime[];
  messengers: {
    telegram?: string;
    viber?: string;
    email?: string;
  };
  socials: {
    instagram?: string;
    facebook?: string;
    youtube?: string;
    tiktok?: string;
    telegram?: string;
    viber?: string;
    twitter?: string;
  };
  offices?: IApiOffice[];
}
