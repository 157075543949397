"use client";
import { useCallback, useMemo, useState } from "react";
import useVisibilityWindow from "@/hooks/useVisibilityWindow";
import { Toasts } from "@/libs/toasts/useToasts";
import { IApiCartItem, IApiCartItemProduct, IApiCartItemVariant } from "@/types/interfaces/cart";
import { TranslateFunc } from "@/hooks/useTranslate";
import { IApiProductDetails } from "@/types/interfaces/products";
import { IApiPublicProductVariant } from "@/types/interfaces/variants";
import analyticsEmitter from "@/components/basic/Analytics/analyticsEmitter";
import { AnalyticsEvents } from "@/components/basic/Analytics/AnalyticsConstants";
import { crudFetcherCommon, crudFetcherNextApi } from "services/crud";
import apiAuthCart from "../constants/apiAuthCart";
import apiRedisCart from "../constants/apiRedisCart";

interface UseCart {
  isReady: boolean;
  isAuthorized: boolean;
  disabled: boolean;
  locale: string;
  currency: string;
  toast: Toasts;
  translate: TranslateFunc;
}

export const normalizeCartProductItem = (
  data: IApiProductDetails
): IApiCartItemProduct => {
  return {
    id: data.id,
    sku: `${data.sku}`,
    name: data.name,
    main_pic: data.main_pic,
    slug: data.slug,
    quantity: data.quantity,
    is_active: data.is_active,
    is_deleted: data.is_deleted,
    is_promoted: data.is_promoted,
  };
};

export const normalizeCartVariantItem = (
  data: IApiPublicProductVariant
): IApiCartItemVariant => {
  return {
    id: data.id,
    name: data.name,
    main_pic: data.main_pic,
    slug: data.slug,
    quantity: data.quantity,
  };
};

/*
  price - real price
  related_price - old price
  defult_price - service field of backend
*/

const useCart = ({
  isReady,
  isAuthorized,
  disabled,
  currency,
  locale,
  toast,
  translate,
}: UseCart) => {
  const [list, setList] = useState<IApiCartItem[]>([]);
  const [isCartAddProcessing, setCartAddProcessing] = useState<any>(false);
  const [isCartDeleteProcessing, setCartDeleteProcessing] =
    useState<any>(false);
  const [isCartUpdateProcessing, setCartUpdateProcessing] =
    useState<any>(false);
  const [isCartCommonProcessing, setCartCommonProcessing] =
    useState<any>(false);

  const quantity = useMemo(
    () =>
      list.reduce(
        (prevValue, currentValue) => prevValue + currentValue.quantity,
        0
      ),
    [list]
  );

  const total = useMemo(() => {
    let t = 0;

    try {
      t = list.reduce((prevValue, currentValue) => {
        return prevValue + currentValue.price_total;
      }, 0);
    } catch (err) {
      console.log("Error total", err, list);
    }

    return t;
  }, [list]);

  const discount = useMemo(() => {
    let t = 0;
    // TODO not implemented on backend
    return t;
  }, []);

  const getList = useCallback(async () => {
    if (disabled) {
      return;
    }

    setCartCommonProcessing(true);

    const getItems = isAuthorized
      ? crudFetcherCommon.get({ url: apiAuthCart.cart(locale) })
      : crudFetcherNextApi.get({
          url: apiRedisCart.cart(),
        });

    const data = await getItems;

    setCartCommonProcessing(false);

    if (Array.isArray(data)) {
      setList(data.sort((a, b) => a.id < b.id ? -1 : 1));
    }
  }, [disabled, isAuthorized, locale]);

  const updateList = useCallback(() => {
    if (isReady) getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList, isReady, isAuthorized]);

  useVisibilityWindow(updateList, true);

  const addProductToCart = useCallback(
    async (data: IApiProductDetails, quantity: number, variant: IApiPublicProductVariant | null) => {
      if (variant ? !variant.quantity : !data.quantity) {
        toast.info(translate("The product is out of stock"));
        return;
      }

      if (disabled) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      const find = list.find((i) => i.product.id === data.id && i.variant?.id === variant?.id);
      if (find) {
        // toast.info(translate("You have already added this product to the cart."));
        return;
      }

      setCartAddProcessing(data?.id);

      const addItem = isAuthorized
        ? crudFetcherCommon.post({
            url: apiAuthCart.cartAddItem(),
            requestData: {
              product: data.id,
              quantity,
              variant: variant?.id || null,
            },
          })
        : crudFetcherNextApi.post({
            url: apiRedisCart.addTocart(),
            requestData: {
              quantity,
              product: data,
              variant,
            },
          });

      const result = await addItem.catch((err) => {
        let text = 'Server error';
        if (err?.non_field_errors && Array.isArray(err?.non_field_errors)) {
          text = err?.non_field_errors.join(`\n`);
        }
        toast.error(text);
      });

      setCartAddProcessing(false);

      if (result) {
        await getList();
        // toast.success(translate("Product has been added to the cart."));
        const quantity = 1;
        const price = parseFloat(data.price);
        const related_price = parseFloat(data.related_price || data.price)
        const discount = related_price - price;
        analyticsEmitter({
          event_name: AnalyticsEvents.addToCart,
          currency,
          value: price,
          items: [{
            quantity,
            price,
            // TODO not exist for purchase event
            discount: 0,
            item_brand: data.brand?.name,
            item_category: `${data.category?.name}`,
            item_id: `${data.id}`,
            item_name: data.name,
          }]
        });
        return result;
      }
    },
    [disabled, list, isAuthorized, toast, translate, getList, currency]
  );

  const deleteProductFromCart = useCallback(
    async (cartId: number, updateList = false) => {
      if (disabled) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      if (updateList) {
        setCartDeleteProcessing(cartId);
      } else {
        setCartCommonProcessing(true);
      }

      const deleteItem = isAuthorized
        ? crudFetcherCommon.delete({
            url: apiAuthCart.cartItem(cartId),
          })
        : crudFetcherNextApi.delete({
            url: apiRedisCart.deleteFromCart(cartId),
          });

      return deleteItem
        .then((data) => {
          if (updateList) {
            getList();
            setCartDeleteProcessing(false);
          }
        })
        .catch(() => {
          setCartDeleteProcessing(false);
          throw new Error(`Error deleting cart item ${cartId}`);
        });
    },

    [disabled, isAuthorized, toast, translate, getList]
  );

  const updateProductInCart = useCallback(
    async (cartId: any, quantity: number, updateList = false) => {
      if (disabled) {
        toast.info(translate("The seller cannot work with the cart"));
        return;
      }

      if (updateList) {
        setCartDeleteProcessing(cartId);
      } else {
        setCartUpdateProcessing(cartId);
      }

      const updateItem = isAuthorized
        ? crudFetcherCommon.patch({
            url: apiAuthCart.cartItem(cartId),
            requestData: {
              quantity,
            },
          })
        : crudFetcherNextApi.put({
            url: apiRedisCart.updateProductCart(cartId),
            requestData: {
              quantity,
            },
          });

      return updateItem.then((data) => {
        setCartUpdateProcessing(false);
        if (updateList) {
          getList();
          setCartDeleteProcessing(false);
        }
      });
    },

    [getList, isAuthorized, disabled, toast, translate]
  );

  const data = useMemo(
    () => ({
      quantity,
      total,
      discount,
      formattedValue: "0.00",
      list,
      isCartAddProcessing,
      isCartDeleteProcessing,
      isCartUpdateProcessing,
      isCartCommonProcessing,
      addProductToCart,
      deleteProductFromCart,
      updateProductInCart,
      updateList,
    }),
    [
      quantity,
      total,
      discount,
      list,
      isCartAddProcessing,
      isCartDeleteProcessing,
      isCartUpdateProcessing,
      isCartCommonProcessing,
      addProductToCart,
      deleteProductFromCart,
      updateProductInCart,
      updateList,
    ]
  );

  return data;
};

export default useCart;
